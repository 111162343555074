<template>
  <slot />
  <a-drawer
    :title="`${ extension ? 'Edit' : 'Add' } Extension`"
    :width="560"
    @close="$emit('cancel')"
    :visible="visible"
  >
    <a-form
      :colon="false"
      layout="vertical"
      :model="values"
      ref="form"
      @finish="onFinish"
    >
      <a-form-item label="Provider" name="provider">
        <a-select
          v-model:value="values.provider"
          name="provider"
        >
          <a-select-option v-for="provider in providers" :value="provider.value">
            {{ provider.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item
        label="API Key"
        name="apiKey"
        :rules="[
          { required: true, message: 'API key is required.' },
        ]"
      >
        <a-input
          v-model:value="values.apiKey"
          :maxlength="64"
          name="apiKey"
        />
      </a-form-item>
    </a-form>
    <template #extra>
      <a-button type="primary" html-type="submit" :loading="loading" @click="onFinish">
        Save
      </a-button>
    </template>
  </a-drawer>
</template>

<script>
export default {
  props: {
    extension: Object,
    visible: Boolean,
  },

  data() {
    return {
      loading: false,
      values: {
        apiKey: this.extension && this.extension.apiKey,
        provider: !this.extension && 'mailchimp',
        service: 'list',
      },
    }
  },

  computed: {
    providers() {
      return [
        { name: 'Mailchimp', value: 'mailchimp' },
      ]
    },
  },

  methods: {
    async onFinish() {
      try {
        var values = await this.$refs.form.validateFields()

        this.loading = true

        if (this.extension) {
          var { data } = await this.$http.put(`/publications/${ this.$store.state.publication.data.id }/extensions/${ this.extension.id }`, values)
        } else {
          var { data } = await this.$http.post(`/publications/${ this.$store.state.publication.data.id }/extensions`, values)
        }

        this.$message.success('Extension has been saved.')
        this.$emit('success', data)
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          console.error(err)
        }
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

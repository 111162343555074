<template>
  <nav aria-label="Navigation">
    <ul class="pagination justify-content-center mb-0">
      <li :class="{ 'page-item': true, 'disabled text-muted': modelValue <= 1 }">
        <span class="page-link" @click="$emit('update:modelValue', modelValue - 1)"><ChevronLeftIcon class="icon" /></span>
      </li>
      <li class="page-item disabled">
        <span class="page-link">{{ modelValue }}<span v-if="pageCount"> of {{ pageCount }}</span></span>
      </li>
      <li :class="{ 'page-item': true, 'disabled text-muted': !hasMore }">
        <span class="page-link" @click="$emit('update:modelValue', modelValue + 1)"><ChevronRightIcon class="icon" /></span>
      </li>
    </ul>
  </nav>
</template>

<script>
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/24/outline'

export default {
  components: {
    ChevronLeftIcon,
    ChevronRightIcon,
  },

  props: {
    hasMore: Boolean,
    modelValue: Number,
    pageCount: Number,
  },
}
</script>

<template>
  <slot />
  <a-modal
    title="Connect List Provider"
    :closable="false"
    :confirmLoading="loading"
    :maskClosable="false"
    :okButtonProps="{ htmlType: 'submit' }"
    okText="Connect"
    :visible="visible"
    @cancel="$emit('cancel')"
    @ok="onFinish"
  >
    <a-form
      :colon="false"
      layout="vertical"
      :model="values"
      ref="form"
      @finish="onFinish"
    >
      <a-form-item
        label="Provider"
        name="provider"
        :rules="[
          { required: true, message: 'API key is required.' },
        ]"
      >
        <a-select v-model:value="values.provider" name="provider">
          <a-select-option v-for="provider in providers" :value="provider.value">
            {{ provider.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item
        label="Data Center"
        name="dataCenter"
        :rules="[
          { required: true, message: 'Data center is required.' },
        ]"
      >
        <a-input
          v-model:value="values.dataCenter"
          :maxlength="16"
          name="dataCenter"
        >
          <template #suffix>
            .admin.mailchimp.com
          </template>
        </a-input>
        <small>
          <p class="text-muted mt-2">Data center as shown in the dashboard’s browser URL (e.g. us9).</p>
        </small>
      </a-form-item>
      <a-form-item
        label="API Key"
        name="apiKey"
        :rules="[
          { required: true, message: 'API key is required.' },
        ]"
      >
        <a-input
          v-model:value="values.apiKey"
          :maxlength="64"
          name="apiKey"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
export default {
  props: {
    visible: Boolean,
  },

  data() {
    return {
      loading: false,
      values: {
        apiKey: null,
        dataCenter: null,
        provider: 'mailchimp',
      },
    }
  },

  computed: {
    providers() {
      return [
        { name: 'Mailchimp', value: 'mailchimp' },
      ]
    },
  },

  methods: {
    async onFinish() {
      try {
        var values = await this.$refs.form.validateFields()

        this.loading = true

        var { data } = await this.$http.post(`/publications/${ this.$store.state.publication.data.id }/extensions`, { ...values, service: 'list' })

        this.$message.success('Extension has been saved.')
        this.$emit('success', data)
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          console.error(err)
        }
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

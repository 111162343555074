<template>
  <div class="d-flex justify-content-between align-items-center mb-4">
    <h1 class="h3 mb-0">
      Extensions <small v-if="extensionsCount !== null" class="text-muted">({{ extensionsCount }})</small>
    </h1>
    <ExtensionCreate
      :visible="visible"
      @cancel="visible = false"
      @success="onSuccess"
    >
      <a-button type="primary" shape="circle" size="large" :disabled="loading" @click="visible = true">
        <PlusIcon class="icon" />
      </a-button>
    </ExtensionCreate>
  </div>
  <BaseSpinner :loading="loading">
    <ExtensionItem
      v-for="(extension, i) in extensions"
      :extension="extension"
      class="mb-3"
      :class="{ 'border-bottom': i < extensionsCount - 1 }"
      @change="listExtensions"
    />
  </BaseSpinner>
  <BasePagination
    class="mt-4"
    v-model="filters.page"
    :hasMore="hasMore"
    :pageCount="Math.ceil(extensionsCount / filters.limit)"
  />
</template>

<script>
import { PlusIcon } from '@heroicons/vue/24/outline'
import BasePagination from '@/components/Base/BasePagination'
import BaseSpinner from '@/components/Base/BaseSpinner'
import ExtensionCreate from '@/components/Extension/ExtensionCreate'
import ExtensionDrawer from '@/components/Extension/ExtensionDrawer'
import ExtensionItem from '@/components/Extension/ExtensionItem'

export default {
  components: {
    BasePagination,
    BaseSpinner,
    PlusIcon,
    ExtensionCreate,
    ExtensionDrawer,
    ExtensionItem,
  },

  data() {
    return {
      loading: true,
      visible: false,
      hasMore: false,
      extensions: [],
      extensionsCount: null,
      filters: {
        limit: 20,
        page: Number(this.$route.query.page) || 1,
      },
    }
  },

  async mounted() {
    await this.listExtensions()
  },

  methods: {
    async listExtensions() {
      try {
        this.loading = true

        var { data } = await this.$http.get(`/publications/${ this.$store.state.publication.data.id }/extensions`, {
          params: this.filters,
        })

        this.extensions = data.extensions
        this.extensionsCount = data.extensionsCount
        this.hasMore = data.hasMore
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          this.$message.error(err.message)
        }
      } finally {
        this.loading = false
      }
    },
    async onSuccess() {
      this.visible = false
      await this.listExtensions()
    },
  },
}
</script>

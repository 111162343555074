<template>
  <div class="card">
    <div class="card-header d-flex justify-content-between align-items-center">
      <div>
        <img width="130" :src="require(`@/assets/images/providers/${extension.provider}.svg`)" :alt="providers[extension.provider]">
      </div>
      <div>
        <a-tag v-if="extension.active" color="green">Active</a-tag>
        <a-tag v-else color="orange">Inactive</a-tag>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-md-6 col-lg-4 mb-4 mb-lg-0">
          <p class="text-overline">Sending Domain</p>
          <strong>{{ extension.metadata.domainName }}</strong>
          <a-tooltip title="Verified" placement="bottom">
            <CheckCircleIcon v-if="extension.metadata.domainVerified" class="icon text-success ms-1" />
          </a-tooltip>
        </div>
        <div class="col-12 col-md-6 col-lg-4 mb-4 mb-lg-0">
          <p class="text-overline">API Key</p>
          <code>{{ extension.metadata.apiKey }}</code>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <p class="text-overline">Data Center</p>
          <strong>{{ extension.metadata.dataCenter }}</strong><span class="text-muted">.admin.mailchimp.com</span>
        </div>
      </div>
    </div>
    <div class="card-footer text-muted">
      <a-tooltip :title="dateFormatted(extension.createdAt)" placement="bottom">
        <small>Connected {{ dateFromNow(extension.createdAt) }}</small>
      </a-tooltip>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { CheckCircleIcon } from '@heroicons/vue/20/solid'

export default {
  components: {
    CheckCircleIcon,
  },

  props: {
    extension: Object,
  },

  data() {
    return {
      providers: {
        mailchimp: 'Mailchimp',
      },
    }
  },

  methods: {
    dateFromNow(date) {
      return moment(date).fromNow()
    },
    dateFormatted(date) {
      return moment(date).format('LLLL')
    },
  }
}
</script>
